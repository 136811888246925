export const PopinBack = {
  props: {
    disableBack: Boolean
  },
  data() {
    return {
      initialPath: null,
      disableRouteOnClose: false,
      fromRoute: null
    }
  },
  watch: {
    $route(to, from) {
      this.fromRoute = from.path
      if (!to.query.popin && !this.stopRedirect) {
        this.$emit('close');
      }
    }
  },
  created() {

    if (!this.disableBack && !this.$route.query.popin) {
      const newRoute = {
        path: this.$route.path,
        query: this.$route.query,
      }
      this.initialPath = {...newRoute};
      // remove also offer popin force opening
      // (click on offer from product page)
      delete this.initialPath.query.offer;
      newRoute.query = {...newRoute.query, popin: Date.now()};
      this.$router.replace(this.localePath(newRoute));
    }
  },
  beforeDestroy() {
    if (!this.disableBack && !this.disableRouteOnClose && !this.stopRedirect && this.$route.query.popin && this.$route.name.indexOf('search__') === -1) {
     this.$router.replace(this.fromRoute);
    }
  }
}

import Vue from "vue";
import ConfirmPopin from "@/components/Popin/ConfirmPopin";

export default async (ctx, inject) => {

  let node = document.createElement('div');
  document.querySelector('body').appendChild(node);
  const popin = new Vue({...ConfirmPopin,...ctx });
  popin.$mount(node);

  const show = (title, desc) => {
    return popin.show(title, desc);
  }

  const hide = () => {
    return popin.hide();
  }

  inject('popin', {
    show,
    hide
  });
}



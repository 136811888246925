import { GTM_EVENTS, USER_LOGIN_STATUSES } from "../assets/js/gtm-events";

export default ({ app }) => {
  if (!process.server) {

    app.router.afterEach((to, from) => {
      if (to.path === from.path) return;

      const userLoginStatus = app.$auth.loggedIn ? USER_LOGIN_STATUSES.loggedIn : USER_LOGIN_STATUSES.loggedOut;
      const separatedPath = to.path.split('/');
      const componentGTMCategory = to.matched.length ? to.matched[0].components.default.options.gtm?.pageView?.category : null;
      const componentGTMTitle = to.matched.length ? to.matched[0].components.default.options.gtm?.pageView?.title : null;

      app.$gtm.push({
        event: GTM_EVENTS.navigation.pageView,
        page: {
          category: componentGTMCategory ?? [...separatedPath].slice(2, separatedPath.length - 1).join('/'),
          language: app.i18n.locale,
          title: componentGTMTitle ?? separatedPath[separatedPath.length - 1],
        },
        user: {
          id: app.$auth.user?.id ?? null,
          login_status: userLoginStatus,
        },
        environment: {
          entity: 'store',
          name: process.env.NODE_ENV,
          source: app.$capacitor ? app.$capacitor.platform : 'web',
        },
      })
    });
  }
};

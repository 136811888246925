import {configure, extend, validate, setInteractionMode} from "vee-validate";
import {email, min, max, required, length, max_value, min_value, regex, confirmed, numeric, size} from "vee-validate/dist/rules";
import {PAYMENT_TYPE, PHONE_PREFIX} from 'assets/js/constants/constant';
import moment from "moment";
import {NETWORK_ERROR} from "@/mixins/filepond";

setInteractionMode('lazy');

export default ({app, redirect, $api}) => {

  configure({
    defaultMessage: (field, values, context) => {
      values._field_ = app.i18n.t(`validations.fields.${field}`);
      if(field === 'phone') {
        return app.i18n.t('validations.messages.phoneColissimo');
      }

      let message = '';
      switch (values._rule_) {
        case 'uploading' :
          message = '';
          break;
        case 'array_between' :
          if(values.min === -1) {
            message = app.i18n.t(`validations.messages.${values._rule_}_max`, values);
          }else if(values.max === -1) {
            message = app.i18n.t(`validations.messages.${values._rule_}_min`, values);
          }else {
            message = app.i18n.t(`validations.messages.${values._rule_}`, values);
          }
          break;
        default :
          message = app.i18n.t(`validations.messages.${values._rule_}`, values);
      }
      return message;

    }
  });
  extend("required", required);
  extend("numeric", numeric);
  extend("email", email);
  extend("min", min);
  extend("max", max);
  extend("max_value", max_value);
  extend("min_value", min_value);
  extend("length", length);
  extend("regex", regex);
  extend("confirmed", confirmed);
  extend("size", size);

  extend('addressAutocomplete', {
    async validate(value) {
      return false;//(value.length >= min || min === -1) && (value.length <= max || max === -1);//res.valid;
    },
  })

  extend('array_between', {
    async validate(value, {min, max}) {
      return (value.length >= min || min === -1) && (value.length <= max || max === -1);//res.valid;
    },
    params: ['min', 'max'],
    //message: 'The {_field_} must be between {min} and {max} digits'
  });

  extend('uploading', {
    async validate(value) {
      return value.every(item => (item !== 'loading'));
    },
    message: ''
  });

  extend('uploaderror', {
    async validate(value) {
      return value.every(item => (item !== 'error' && item !== 'errorSize' && item !== NETWORK_ERROR));
    },
    message: 'Erreur lors du traitement de vos images'
  });

  extend('checkemail', {
    async validate(value) {
      try {
        const response = await $api.get(process.env.apiUrl + '/api/v1/auth/register/check-email', {params: {email : value}});
        return !response.data.exists;
      }catch(e) {
        return true;
      }
    },
    message: '{_value_} est déjà utilisé'
  })

  extend('pseudo', {
    async validate(value) {
      try {
        const response = await $api.get(process.env.apiUrl + '/api/v1/auth/register/check-pseudo', {params: {pseudo : value}});
        return !response.data.exists;
      }catch(e) {
        return true;
      }
    },
    message: '{_value_} est déjà utilisé'
  })

  const checkString = (value) => {
    return (typeof value !== 'undefined' && value !== null && value.toString().length > 0);
  }

  extend('address', {
    async validate(value) {
      const toCheck = [
        checkString(value.houseNumber),
        checkString(value.address),
        checkString(value.city),
        checkString(value.zipcode),
        checkString(value.isoCode),
      ];
      return toCheck.every(item => {
        return item === true
      });
    },
  });

  extend('not_dom_tom', {
    params: ['country'],
    async validate(value, { country }) {
      return !(country === 'FR' && (value.startsWith('97') || value.startsWith('98')))
    },
  });

  /*
  extend('transporter', {
    async validate(value) {
      return value.id;// && (!value.hasPickup || (value.servicePoint && value.servicePoint.id));
    },
  });*/

  extend('transporter', {
    async validate(value) {
      return (!value.hasPickup || (value.servicePoint && value.servicePoint.id));
    },
  });

  extend('phone', {
    params: ['countryPhone'],
    async validate(value, { countryPhone }) {
      if (countryPhone && countryPhone.isoCode !== PHONE_PREFIX.other) {
        const libphonenumber = await import('google-libphonenumber');
        const PhoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

        let number = null

        try {
          if (countryPhone) {
            number = PhoneUtil.parse(value, countryPhone.isoCode);
          } else {
            number = PhoneUtil.parse(value, 'FR');
          }


          return PhoneUtil.isValidNumber(number) && /^(?:[+\d].*\d|\d)$/.test(value);
        } catch (e) {
          return false;
        }
      } else {
        return /^(?:[+\d].*\d|\d)$/.test(value);
      }

    },
  });

  extend('payment', {
    async validate(value) {
      if (value) {
        if (value.paymentMethod === PAYMENT_TYPE.card || value.paymentMethod === PAYMENT_TYPE.bcmc) {
          return value.card?.id !== null && value.card?.id.length > 0
        }
        if (PAYMENT_TYPE[value.paymentMethod]) {
          return true
        }
      }
      return false
    }
  });

  extend('iban', {
    async validate(value) {
      const IBAN = await import('iban');
      return IBAN.isValid(value);
    },
  });

  extend('birthdate', {
    async validate(value) {
      let error = false;

      if(value.length === 10) {
        const now = moment()
        const date = moment(value, "DD/MM/YYYY")
        if (!date.isValid() || date.isSameOrAfter(moment()) || date.diff(now, 'years') < -100) {
          error = true
        }
      }else {
        error = true;
      }
      return !error;
    },
  });

  extend('zipcode', {
    async validate(value) {
      const { postcodeValidator } =  await import('postcode-validator');
      return postcodeValidator(value,'FR');
    },
  });

};

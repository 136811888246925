export default async ({$axios,store,app}, inject) => {

  // 200 -> OK
  // 503 -> maintenance
  //https://api.dev.secondo.app/

  if (process.client) {
    if(store.getters?.getConfig?.app?.appDown) {
      store.dispatch('openForm', {
        width: 'regular',
        component: 'status',
        clickOutside : false,
        data: {
          title : app.i18n.t('app.maintenance.title'),
          desc : app.i18n.t('app.maintenance.desc'),
        }
      })
    }
  }
}

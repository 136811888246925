import {LOGIN_STEPS} from "assets/js/constants/constant";

export default ({ app, route, next, localePath, redirect, store}) => {
  function isProtected(route) {
    return route.matched.some((m) => {
      return Object.values(m.components).some((component) => component.options && component.options['auth'] !== false && component.options['auth'] !== 'guest')
    })
  }

  if (isProtected(route) && !app.$auth.loggedIn) {
    store.state.auth.redirect = route.fullPath
    if (process.client) {
      next(false)
      store.dispatch('openForm', {
        width: 'regular',
        component: 'login',
        type: 'popinV2',
        data: {
          origin: route.name
        }
      })
    } else {
      redirect(localePath({
        name: 'profil',
        query: {
          auth: true,
          origin: route.name,
          redirect: route.fullPath
        }
      }));
    }
  }

}

export default function(context) {
  if (context.app.$device.isAndroid) {
    return context.redirect("market://details?id=com.everide.app");

  } else if (context.app.$device.isIos) {
    return context.redirect("https://apps.apple.com/fr/app/everide/id1587901394");
  } else {
    return context.redirect(context.localePath({
      name: 'index',
      replace: true
    }));
  }
}

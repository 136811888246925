import {debounce} from "underscore";
import Vue from "vue";

export default async function (ctx, inject) {

  let breakpoint = null;

  const breakpoints = [
    //{name: '2xl', screen: 1536},
    {name: 'xl', screen: 1280},
    {name: 'lg', screen: 1024},
    {name: 'md', screen: 768},
    //{name: 'sm', screen: 640},
    {name: 'xs', screen: 0}
  ];

  const checkBreakpoint = () => {
    let breakpoint = {}
    if (typeof window !== 'undefined') {
      const b = breakpoints.find(size => window.matchMedia(`(min-width : ${size.screen}px)`).matches);

      if (b) {
        if (ctx.$screen) {
          ctx.$screen.current = b;
          ctx.$screen.xs = b.name === 'xs';
          //ctx.$screen.sm = b.name === 'sm';
          ctx.$screen.md = b.name === 'md';
          ctx.$screen.lg = b.name === 'lg';
          //ctx.$screen.xl = b.name === 'xl';
          ctx.$screen.mobile = b.name === 'xs' || b.name === 'sm' || b.name === 'md';
          ctx.$screen.desktop = b.name === 'lg'  || b.name === 'xl'; //&& b.name !== '2xl';
        } else {
          breakpoint = {
            current: b,
            xs: b.name === 'xs',
           // sm: b.name === 'sm',
            md: b.name === 'md',
            lg: b.name === 'lg',
            //xl: b.name === 'xl',
            mobile: b.name === 'xs' || b.name === 'md',
            desktop: b.name === 'lg' || b.name === 'xl'// && b.name !== '2xl',
            //'2xl' : b.name === '2xl',
          };
        }
      }
    } else {
      let current;
      if(ctx.$device.isMobile) {
        current  = {name: 'xs', screen: 0};
      }else if(ctx.$device.isTablet) {
        current = {name: 'md', screen: 768};
      }else {
        current = {name: 'lg', screen: 1024};
      }

      breakpoint = {
        current,
        xs: ctx.$device.isMobile,
        md: ctx.$device.isTablet,
        lg: ctx.$device.isDesktop,
        //xl: ctx.$device.isDesktop,
        mobile: ctx.$device.isMobile || ctx.$device.isTablet,
        desktop: ctx.$device.isDesktop
      };
    }
    return breakpoint
  }

  const appHeight = () => {
    if (typeof document !== 'undefined') {
      document.documentElement.style.setProperty('--vh', (window.innerHeight*0.01) + 'px');
    }
  }

  appHeight()
  const screen = checkBreakpoint(ctx);

  if (typeof window !== 'undefined') {
    inject('screen', Vue.observable(screen))
    window.addEventListener('resize', () => {
      setTimeout(() => {
        appHeight()
        const newScreen = checkBreakpoint(ctx)
        for (let key in newScreen) {
          ctx.$screen[key] = newScreen[key]
        }
      })
    })
  } else {
    inject('screen', screen);
  }
}

export default async function ({$cookies, store, $gtm}, inject) {

  const cookies = [
    {
      id: 1,
      mandatory: true
    },
    {
      id: 2,
      mandatory: false,
      accepted: () => {
        $gtm.init('GTM-KNDDP4R')
      },
      declined: () => {
        for (let cookie in $cookies.getAll()) {
          if (cookie.startsWith('_g') || cookie.startsWith('_f')) {
            $cookies.remove(cookie, { path: '/', domain: '.everide.test' })
          }
        }
      }
    }
  ]

  const setAcceptedCookies = (cookies) => {
    $cookies.set('cookies.accepted', cookies, {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 2))
    })
  }

  const getAcceptedCookies = () => {
    return $cookies.get('cookies.accepted')
  }

  if (!process.env.mobileApp) {
    await store.dispatch('cookies/initCookies', getAcceptedCookies() === undefined);
    if (process.client && getAcceptedCookies()) {
      getAcceptedCookies().forEach(cookieId => {
        const cookie = cookies.filter(cookie => cookie.id === cookieId)[0]
        if (cookie?.accepted) cookie.accepted()
      })
    }
  }

  inject('cookieManager', {
    cookies,
    setAcceptedCookies,
    getAcceptedCookies
  });
}

import moment from "moment";

export default ({app}, inject) => {
  const compare = (dateA, dateB, space) => {
    const isOver = Math.floor((new Date(dateA) / 1000 - new Date(dateB) / 1000)) > space;
    return isOver ? new Date(new Date(dateA) - new Date(dateB)).toISOString() : false;
  }
  const elapsed = dateString => {
    const date = new Date(dateString);
    const seconds = Math.floor((new Date() - date) / 1000);
    return seconds;
  }
  const since = dateString => {

    if (dateString) {

      const date = new Date(dateString);
      const seconds = Math.floor((new Date() - date) / 1000);
      let interval = seconds / 31536000;
      if (interval > 1) {
        return moment(date).format("DD/MM/YYYY")
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return moment(date).format("DD/MM/YYYY")
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return moment(date).format("DD/MM/YYYY")
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + ' ' + app.i18n.tc('time.hours', Math.floor(interval));
      }
      interval = seconds / 60;

      if (interval > 1) {
        return Math.floor(interval) + ' ' + app.i18n.tc('time.minutes', Math.floor(interval));
      }
      return Math.floor(seconds) + ' ' + app.i18n.tc('time.seconds', Math.floor(interval));
    } else {
      return '';
    }
  }

  const interval = (seconds, includeDays) => {


    interval = seconds / 86400;

    if (interval > 7 && includeDays) {
      return app.i18n.t('time.more.7j')
    }

    if (interval > 1 && includeDays) {
      return Math.floor(interval) + ' ' + app.i18n.tc('time.days', Math.floor(interval));
    }

    interval = seconds / 86400;
    if (interval > 1) {
      return app.i18n.t('time.more.24h')
    }

    let interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + app.i18n.tc('time.hours', Math.floor(interval));
    }
    interval = seconds / 60;

    if (interval > 1) {
      return Math.floor(interval) + app.i18n.tc('time.minutes', Math.floor(interval));
    }
    return Math.floor(seconds) + app.i18n.tc('time.seconds', Math.floor(interval));
  }

  const apiFormat = date => {
    let dateString = date.split('').reduce((str, l, i) => {
      if (i === 1 || i === 3) {
        return str + l + '-'
      }
      return str + l;
    }, '')
    let dateArray = dateString.split("-");
    return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
  }

  const inputFormat = date => {
    let dateArray = date.split(' ')[0].split('-')
    return `${dateArray[2]}${dateArray[1]}${dateArray[0]}`
  }

  const birthdateToUS = date => {

    const fullDateFormat = (str, l, i) => {
      if (i === 1 || i === 3) {
        return str + l + '-'
      }
      return str + l;
    }

    const eu = date.replace(/[^0-9]/g, '').substr(0, 8).split('').reduce(fullDateFormat, '');
    const splitted = eu.split('-');

    return splitted[2] + '-' + splitted[1] + '-' + splitted[0];
  }

  inject('time', {
    since,
    interval,
    elapsed,
    compare,
    apiFormat,
    inputFormat,
    birthdateToUS
  });
}

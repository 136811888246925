<template>

  <client-only v-if="showIntroductionSteps">
    <IntroductionSteps @enable-notifications="enableNotifications" @skip-notifications="skipNotifications" />
  </client-only>

  <div v-else class="relative lg:pt-0 flex flex-col w-full items-stretch min-h-full"
       :style="style">
    <div class="flex flex-col w-full min-h-full lg:min-h-screen">
      <Header v-if="!isApp" class="hidden transition-opacity lg:block" />
      <client-only>
        <ToastManager/>
      </client-only>
      <div class="relative flex flex-grow bg-white lg:bg-surface-light" >
        <Nuxt class="w-full flex-grow notch-pt before-notch-pt"
              keep-alive
              :keep-alive-props="keepAliveProps"/>
      </div>
    </div>
    <client-only>
      <CookiePopin v-if="showCookiePopin" />
      <PopinManager />
      <OverlayManager/>
      <NavBar v-if="mobileNavBar"/>
    </client-only>
    <Footer v-if="!$screen.mobile"/>
  </div>
</template>

<script>

import Header from "~/components/header/Header.vue";
import Footer from "@/components/footer/Footer";
import { PushNotifications } from "@capacitor/push-notifications";
import { Storage } from "@capacitor/storage";
import {NOTIFICATIONS_STATUS, STORAGE_KEYS} from "assets/js/constants/constant";
import CookiePopin from "@/components/Popin/CookiePopin";
import { mapGetters } from "vuex";
import IntroductionSteps from "@/components/splashcreen/IntroductionSteps.vue";
import ToastManager from "@/components/ToastManager.vue";
import PopinManager from "@/components/Popin/PopinManager.vue";
import OverlayManager from "@/components/Popin/OverlayManager.vue";
import NavBar from "@/components/footer/NavBar.vue";
export default {
  components: {Header, NavBar, OverlayManager, PopinManager, ToastManager, CookiePopin, Footer, IntroductionSteps},
  data: () => ({
    initialScrollPosition: 0,
    showIntroductionSteps: false,
    isBack: false,
    pStart: {x: 0, y: 0},
    isLoading: false,
    offset: 0,
    pullStarted: false,
    pullTimeout : -1
  }),
  mounted() {
    if (process.env.mobileApp) {
      Storage.get({ key: STORAGE_KEYS.notificationToken }).then(({ value: notificationToken }) => {
        window['console'].log('--NOTIFICATION TOKEN', notificationToken);
      });

      Storage.get({ key: STORAGE_KEYS.hasSeenIntroductionSteps }).then(({ value: introductionStepsSeenDate }) => {
        const { d_start: apiIntroDStart } = this.config.app.intro
        const seenTime = introductionStepsSeenDate ? Math.floor(new Date(introductionStepsSeenDate).getTime() / 1000) : 0;
        if (seenTime < apiIntroDStart ?? 0) this.showIntroductionSteps = true;
      });
    }
  },
  computed: {
    ...mapGetters({
      showCookiePopin: 'cookies/getShowCookiePopin',
      isPopinOpen: 'overlayForm/component',
      config: 'config/getConfig'
    }),
    notchPt({$route}) {
      return !(($route.name.indexOf('product-slug') > -1 && $route.name.indexOf('-update') === -1) || $route.name.indexOf('shop-pseudo') > -1 || $route.name.indexOf('profil-shop') > -1)
    },
    keepAliveProps() {
      return {include: ['homepage', 'all', 'brand', 'productPayment']}//{max: 5, include: ['all', 'homepage']}
    },
    mobileNavBar() {
      // Only for mobile = < 768px
      if (!this.$screen.mobile) {
        return false
      }
      // Config into the meta page
      if (this.$route?.meta?.mobileNavBar !== undefined) {
        return this.$route?.meta?.mobileNavBar
      }

      return true
    },
    isApp() {
      return process.env.mobileApp
    },
    style() {
      if (this.isPopinOpen) {
        this.initialScrollPosition = window.scrollY
        return {
          top: `-${this.initialScrollPosition}px`,
          position: 'fixed'
        }
      } else {
        return null
      }
    }
  },
  watch: {
    isPopinOpen(value) {
      if (!value) {
        this.$nextTick(() => {
          window.scrollTo(0, parseInt(this.initialScrollPosition));
        })
      }
    }
  },
  methods: {
    async enableNotifications() {
      if (this.$capacitor) {
        await Storage.set({ key: STORAGE_KEYS.enabledNotifications, value: NOTIFICATIONS_STATUS.later });

        PushNotifications.requestPermissions().then(async result => {
          await PushNotifications.register();
          window['console'].log(result.receive)

          if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            await Storage.set({ key: STORAGE_KEYS.enabledNotifications, value: NOTIFICATIONS_STATUS.true });
          } else {
            await Storage.set({ key: STORAGE_KEYS.enabledNotifications, value: NOTIFICATIONS_STATUS.false });
          }
        });
      }

      await this.closeIntroductionSteps();
    },
    async skipNotifications() {
      await Storage.set({ key: STORAGE_KEYS.enabledNotifications, value: NOTIFICATIONS_STATUS.later });
      await this.closeIntroductionSteps();
    },
    async closeIntroductionSteps() {
      await Storage.set({ key: STORAGE_KEYS.hasSeenIntroductionSteps, value: new Date().toISOString() });
      this.showIntroductionSteps = false;
    }
  }
}
</script>

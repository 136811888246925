<template>
  <client-only>
    <div class="h-full flex items-center justify-center pb-footer-safe bg-white">
      <div class="font-heading flex flex-col items-center">
        <EmptyResults :icon="icon"
                      :title="title"
                      :description="description" />
        <nuxt-link class="" replace
                   :to="localePath({name : 'index'})">
          <Button class="whitespace-nowrap text-18 py-6"
                  color="primary">
            {{ $t('page.404.cta') }}
          </Button>
        </nuxt-link>
      </div>
    </div>

  </client-only>
</template>

<script>
import EmptyResults from "@/components/global/EmptyResults.vue"
import Button from "@/components/buttons/Button.vue"

export default {
  components: {Button, EmptyResults},
  props: ['error'],
  computed: {
    title() {
      return this.error.statusCode === 404 ? this.$t('page.404.title') : this.$t('page.500.title');
    },
    description() {
      return this.error.statusCode === 404 ? this.$t('page.404.description') : this.$t('page.500.description');
    },
    icon() {
      return this.error.statusCode === 404 ? 'location' : 'signal';
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>


<template>
  <client-only v-if="showIntroductionSteps">
    <IntroductionSteps @enable-notifications="enableNotifications" @skip-notifications="skipNotifications" />
  </client-only>

  <div v-else class="relative lg:pt-0 flex flex-col w-full items-stretch min-h-full notch-pt">
    <div ref="view" class="flex flex-col w-full min-h-full lg:min-h-screen">
      <client-only>
        <ToastManager />
      </client-only>
      <div class="flex flex-grow bg-white lg:bg-surface-light">
        <Nuxt class="w-full relative flex-grow bg-white" keep-alive :keep-alive-props="keepAliveProps" />
      </div>
    </div>
    <client-only>
      <PopinManager />
      <OverlayManager />
      <NavBar :class="{'hidden' : !hasNavBar}" />
    </client-only>
  </div>
</template>

<script>
import Loader from "~/assets/svg/loader.svg?inline";
import {PushNotifications} from "@capacitor/push-notifications";
import {Storage} from "@capacitor/storage";
import {NOTIFICATIONS_STATUS, STORAGE_KEYS} from "assets/js/constants/constant";
import IntroductionSteps from "@/components/splashcreen/IntroductionSteps.vue"
import {mapGetters} from "vuex";
import ToastManager from "~/components/ToastManager.vue"
import PopinManager from "~/components/Popin/PopinManager.vue"
import OverlayManager from "~/components/Popin/OverlayManager.vue"
import NavBar from "~/components/footer/NavBar.vue"

export default {
  components: {NavBar, OverlayManager, PopinManager, ToastManager, IntroductionSteps, Loader},
  computed: {
    ...mapGetters({
      config: 'config/getConfig'
    }),
    keepAliveProps({$route}) {
      return {include: ['all', 'homepage', 'search']}//max: 10,
    },
    hasNavBar({$route}) {
      if (!$route.name) {
        return true;
      }
      let response;
      switch (true) {
        case $route.name.indexOf('product-slug') > -1 && this.$screen.mobile:
        case $route.name.indexOf('product-add') > -1 && this.$screen.mobile:
        case $route.name.indexOf('conversations-thread') > -1 && this.$screen.mobile:
          response = false;
          break;
        default :
          response = true;
      }
      return response;
    },
  },
  data: () => ({
    showIntroductionSteps: false,
    isBack: false,
    pStart: {x: 0, y: 0},
    isLoading: false,
    offset: 0,
    pullStarted: false,
    pullTimeout : -1
  }),
  mounted() {
    if (process.env.mobileApp) {
      Storage.get({ key: STORAGE_KEYS.notificationToken }).then(({ value: notificationToken }) => {
        window['console'].log('--NOTIFICATION TOKEN', notificationToken);
      });

      Storage.get({ key: STORAGE_KEYS.hasSeenIntroductionSteps }).then(({ value: introductionStepsSeenDate }) => {
        const { d_start: apiIntroDStart } = this.config.app.intro
        const seenTime = introductionStepsSeenDate ? Math.floor(new Date(introductionStepsSeenDate).getTime() / 1000) : 0;
        if (seenTime < apiIntroDStart ?? 0) this.showIntroductionSteps = true;
      });
    }
  },
  methods: {
    async enableNotifications() {
      if (this.$capacitor) {
        await Storage.set({ key: STORAGE_KEYS.enabledNotifications, value: NOTIFICATIONS_STATUS.later });

        PushNotifications.requestPermissions().then(async result => {
          await PushNotifications.register();

          window['console'].log(result.receive)
          if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            await Storage.set({ key: STORAGE_KEYS.enabledNotifications, value: NOTIFICATIONS_STATUS.true });
          } else {
            await Storage.set({ key: STORAGE_KEYS.enabledNotifications, value: NOTIFICATIONS_STATUS.false });
          }
        });
      }

      this.closeIntroductionSteps();
    },
    async skipNotifications() {
      await Storage.set({ key: STORAGE_KEYS.enabledNotifications, value: NOTIFICATIONS_STATUS.later });
      await this.closeIntroductionSteps();
    },
    async closeIntroductionSteps() {
      await Storage.set({ key: STORAGE_KEYS.hasSeenIntroductionSteps, value: new Date().toISOString() });
      this.showIntroductionSteps = false;
    }
  }
}
</script>

import {updateRead} from "@/assets/js/utils/user"

export default ({route, app, store, $api, $root, $nuxt, redirect}, inject) => {
  if (!process.server) {

    let readTimeout = -1;
    let isFirst = true;

    app.router.beforeEach((to, from, next) => {

      updateRead(app, store, $api);

      if ((from.name === null || from.name.indexOf('index__') === 0) && isFirst) {
        store.commit('setBackHome',true);
        isFirst = false;
      } else {
        store.commit('setBackHome',false);
      }


      if (to.name && to.name.indexOf('index__') > -1) {
        store.commit('setHistory', [
          {path : '/' + app.i18n.locale + '/', back : false, depth : 0}
        ]);
      } else if(to.name) {
        if((to.name.indexOf('product-add') > -1 || to.name.indexOf('product-slug-update') > -1) ) {
          store.commit('setHistory', [
            {
              path : from.path,
              back : false,
              skipFirstHistory: true,
              depth : 0
            }
          ]);
        } else {
          const memoryPath = to.fullPath;
          const exclude = [
            to.name !== null && to.name !== undefined,
            //Object.keys(to.query).length === 0,
            !to.query.popin,
            to.name && to.name.indexOf('brand') === -1 || (to.name.indexOf('brand') === 0 && to.query.brands !== undefined),
            //to.name && to.name.indexOf('product-slug-payment') === -1,
            to.name && to.name.indexOf('product-slug-payin') === -1,
            to.name && to.name.indexOf('callback') === -1,
            to.name && to.name.indexOf('product-slug-success') === -1,
            to.name && to.name.indexOf('product-add') === -1,
            to.name && to.name.indexOf('product-slug-update') === -1,
          ];

          const historyUpdated = store.getters.getHistory.map((item, i) => ({
            ...item,depth : item.depth + 1, back: (i === store.getters.getHistory.length - 1) && (item.depth + 1) === 1
          }));

          if (exclude.every(item => item === true) && historyUpdated.every(item => item.path !== memoryPath)) {//&& !history.includes(memoryPath)
            store.commit('setHistory', [...historyUpdated, {
              depth : 0,
              path: memoryPath,
              back: true
            }]);
          } else {

            const historyUpdated2 = store.getters.getHistory.map((item, i) => ({
              ...item,depth : 10, back: false
            }));

            store.commit('setHistory', [...historyUpdated2]);
          }
        }
      }
      next();

      clearTimeout(readTimeout);
      readTimeout = setTimeout(() => {
        updateRead();
      },5000);
    });
  }
};

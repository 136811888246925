export default ({ app }) => {

  app.$auth.onRedirect((to) => {
    return app.localePath(to);
  })

  const redirect = app.$auth.$storage.options.redirect;
  for (let key in redirect) {
    redirect[key] = key === 'home' ? false : '/' + app.i18n.locale + redirect[key]
  }
  app.$auth.$storage.options.redirect = redirect;
}

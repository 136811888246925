import * as FilePond from "filepond";
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';

FilePond.registerPlugin(FilePondPluginImageResize);
FilePond.registerPlugin(FilePondPluginImageTransform);
FilePond.registerPlugin(FilePondPluginFileRename);
FilePond.registerPlugin(FilePondPluginImageExifOrientation);

export const NETWORK_ERROR = 'errorNetwork'

export const FileUpload = {
  data() {
    return {
      filePondInstance: null
    }
  },
  mounted() {
    FilePond.setOptions({
      server: {
        //timeout : 99999999,
        url: process.env.apiUrl + '/filepond/api',
        process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
          const url = process.env.apiUrl + '/filepond/api';

          const formData = new FormData();
          formData.append('file', file, file.name);

          // related to aborting the request
          const CancelToken = this.$axios.CancelToken;
          const source = CancelToken.source();

          // the request itself
          this.$axios({
            method: 'post',
            url: url + '/process',
            data: formData,
            cancelToken: source.token,
            onUploadProgress: (e) => {
              // updating progress indicator
              progress(e.lengthComputable, e.loaded, e.total);
            }
          }).then(response => {
            // passing the file id to FilePond

            load(response.data);
          }).catch((thrown) => {

            if (this.$axios.isCancel(thrown)) {

            } else {
              if (!thrown.response) {
                error(NETWORK_ERROR);
              } else {
                load('error');
              }
            }
          });

          // Setup abort interface
          return {
            abort: () => {
              source.cancel('Operation canceled by the user.');
            }
          };
        },
        revert: (uniqueFileId, load, error) => {
          const url = process.env.apiUrl + '/filepond/api';

          // related to aborting the request
          const CancelToken = this.$axios.CancelToken;
          const source = CancelToken.source();
          // the request itself
          this.$axios({
            headers: {
              //'Content-Length': 0,
              'Content-Type': 'text/plain'
            },
            method: 'delete',
            url: url + '/process',
            data: uniqueFileId,
            cancelToken: source.token,
            onUploadProgress: (e) => {
              // updating progress indicator
              //progress(e.lengthComputable, e.loaded, e.total);
            }
          }).then(response => {
            // passing the file id to FilePond
            load(response.data)
          }).catch((thrown) => {
            if (this.$axios.isCancel(thrown)) {

            } else {
              // handle error
            }
          });
          // Setup abort interface
          return {
            abort: () => {
              source.cancel('Operation canceled by the user.');
            }
          };
        },
      }
    });

    this.filePondInstance = FilePond.create({
      fileRenameFunction: (file) => {
        return `everide-${Date.now()}-${Math.round(Math.random() * 10000)}${file.extension}`;
      },
      name: 'filepond',
      allowMultiple: true,
      allowDrop: false,
      allowPaste: false,
      dropOnElement: false,
      maxParallelUploads : 1,
      maxFiles: 12,
      allowBrowse: false,
      instantUpload: false,
      allowImageResize: true,
      imageResizeTargetWidth: 2000,
      imageTransformOutputMimeType: 'image/jpeg',
      imageTransformOutputQuality: 90,
      //chunkUploads : true
    });

    this.filePondInstance.on('error', (error, file) => {

    });
    this.filePondInstance.on('processfileprogress', (e) => {

    });
    this.filePondInstance.on('processfileabort', (e) => {

    });
  },
  methods: {
    async filePondProcessFile(file) {
      const f = await this.filePondInstance.addFile(file);
      return await this.filePondInstance.processFile(f.id);
    },
    async filePondAddFile(file) {
      return await this.filePondInstance.addFile(file);
    },
    async filePondUploadFiles() {
      const files = await this.filePondInstance.processFiles();
      return files.map(item => item.serverId);
    },
    async filePondRemoveFile(filepondID) {
      return await this.filePondInstance.removeFile(filepondID);
    }
  },
  beforeDestroy() {
    if(this.filePondInstance) {
      this.filePondInstance.destroy();
      this.filePondInstance = null;
    }
  }
}


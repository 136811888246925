import SmartBanner from 'smart-app-banner/dist/smart-app-banner'

export default async function ({app}) {
  if (typeof window !== 'undefined' && !process.env.mobileApp) {
    new SmartBanner({
      daysHidden: 1,   // days to hide banner after close button is clicked (defaults to 15)
      daysReminder: 1, // days to hide banner after "VIEW" button is clicked (defaults to 90)
      title: app.i18n.t('smartBanner.app'),
      author: app.i18n.t('smartBanner.author'),
      button: app.i18n.t('smartBanner.cta'),
      store: {
        ios: app.i18n.t('smartBanner.apple'),
        android: app.i18n.t('smartBanner.android'),
      },
      price: {
        ios: app.i18n.t('smartBanner.free'),
        android: app.i18n.t('smartBanner.free'),
      },
      theme: 'ios', // put platform type ('ios', 'android', etc.) here to force single theme on all device
      icon: process.env.staticAssets + '/site/icon.png', // full path to icon image if not using website icon image
      //force: 'ios' // Uncomment for platform emulation
    });
  }
}
